*,
:after,
:before {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  min-width: 1200px;
}

body {
  background: rgb(54, 54, 69);
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  font: 18px "Inter", system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
}

@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

/* split layout */

.split-layout {
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.split-layout main {
  flex: 1 1 50%;
  width: 100%;
  max-width: 50%;

  min-height: 100vh;
  padding: 3em 1em 3em 1em;
}

.split-layout aside {
  flex: 1 1 50%;
  width: 100%;
  max-width: 50%;
  padding-top: 3em;
}

.split-layout main .page {
  background: #000;
  border-radius: 1em;
  padding: 0 0 2em 0;
  max-width: 650px;
}

.split-layout main section {
  position: relative;
  width: 100%;
  padding: 1em 2em;
}

/* global styles */

a {
  text-decoration: none;
  color: lightblue;
  border-bottom: 2px solid rgb(173, 216, 230, 0.3);
}

h1 {
  font-size: 4em;
  font-weight: 700;
  color: lightblue;
  margin: 0.45em 0 0.2em 0;
}

p {
  max-width: 520px;
  line-height: 1.5em;
  font-size: 1.1em;
  color: lightgray;
}

.bubble-wrapper {
  text-align: right;
}

p.bubble {
  max-width: 650px;
  display: inline-block;
  background: rgb(54, 54, 69);
  background: #0077ffbc;
  /* background: #006effb6; */
  color: white;

  padding: 0.8em 1.25em;
  border-radius: 1em 1em 0 1em;

  margin-bottom: 2em;
}

p + p {
  margin-top: 1em;
}

pre + p {
  margin-top: 1em;
}

p + pre {
  margin-top: 1em;
}

p + p.bubble {
  margin-top: 1em;
}

p.bubble + p {
  margin-top: 1em;
}

p.bubble span {
  margin-right: 0.25em;
}

p code {
  color: lightgray;
  font-family: inherit;
  font-size: 1em;
  padding: 0.04em 0.5em 0.2em 0.5em;
  background: rgb(41, 41, 54);
  border-radius: 1em;
}

p mark {
  color: rgb(255, 255, 175);
  background: inherit;
}

p code.special {
  background: rgb(25, 61, 78);
}

pre {
  width: 100%;
  overflow: auto;
}

pre code {
  font-size: 1.1em;
  color: lightsteelblue;
  font-family: "Andale Mono", AndaleMono, monospace;
  line-height: 1.5em;
}

ul {
  font-size: 15px;
}

ul li {
  margin-top: 1em;
  line-height: 1em;
  /* word-break: break-all; */
  margin-left: 1em;
}

ul li p {
  line-height: 1.45em;
  color: lightgrey;
}

/* keys */

.shortcut {
  display: inline-block;
  color: lightblue;
  font-weight: 500;
  border: 2px solid lightblue;
  border-radius: 0.45em;

  min-width: 2.27em;
  min-height: 2.75em;

  padding: 0 0.6em;
  line-height: 3em;
  text-align: center;
  user-select: none;
  /* box-shadow: 0 1px 5px 0 rgba(9, 17, 33, 0.39); */
  transition: background-color 120ms ease-out;
  overflow: hidden;
}

.shortcut.pressed {
  background: lightblue;
  color: #333;
}

/* asd demo */

.grid-asd {
  margin-top: 1em;
  display: grid;

  grid-template-columns: repeat(3, 3.27em);
  grid-template-rows: repeat(1, 3.27em);
  grid-gap: 10px;
}

.grid-asd .highlight {
  transition: background-color 150ms ease-in-out;
  grid-column: 1/4;
  grid-row: 1;
  border-radius: 10px;

  display: grid;
  grid-template-columns: repeat(3, 3.27em);
  grid-template-rows: repeat(1, 3.27em);
  grid-gap: 10px;
}

.grid-asd .highlight.active {
  background: rgba(173, 216, 230, 0.5);
}

.grid-asd .highlight.a {
  grid-column: 1;
  grid-row: 1;
}

/* keyboard demo */

.grid {
  display: grid;
  grid-template-columns: repeat(7, 3.27em);
  grid-template-rows: repeat(2, 3.27em);
  grid-gap: 10px;
  margin: 0 auto;
  /* lol */
  width: calc(7 * 3.27em + 6 * 10px);
}

.shortcut.esc {
  grid-column: 1;
  grid-row: 1;
}

.shortcut.tab {
  grid-column: 1;
  grid-row: 2;
}

.shortcut.shift-left {
  grid-column: 7;
  grid-row: 2;
}

.shortcut.up {
  grid-column: 4;
  grid-row: 1;
}

.shortcut.left {
  grid-column: 3;
  grid-row: 2;
}

.shortcut.down {
  grid-column: 4;
  grid-row: 2;
}

.shortcut.right {
  grid-column: 5;
  grid-row: 2;
}

.shortcut.return {
  grid-column: 6/8;
  grid-row: 1;
}
