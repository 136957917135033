.grid-shadow {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  border-radius: 20px;
}

.grid-canvas.is-dragging .grid-shadow {
  opacity: 1;
}

.grid-canvas .grid-item {
  outline: 0;
}

.grid-canvas .grid-item:focus-within .grid-window {
  border: 8px solid #000;
}

.grid-canvas .grid-item:nth-child(2) .grid-window {
  background-color: #ff6d6d;
}

.grid-canvas .grid-item:nth-child(4) .grid-window {
  background-color: #089b53;
}

.grid-canvas .grid-item:nth-child(6) .grid-window {
  background-color: #ffc016;
}

.grid-canvas .grid-item:nth-child(8) .grid-window {
  background-color: #43a8e9;
}

.grid-canvas .grid-item:nth-child(10) .grid-window {
  background-color: #944ed2;
}
